@import "shared";

// // buttons
button {
  &.main-button {
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.1em;
    border-radius: $border-radius-small;
  }

  &.full-width {
    @extend .main-button;
    width: 100%;
  }
}

.heading {
  color: var(--mat-primary-500);
  text-transform: uppercase;
  font-size: 0.7em;
  letter-spacing: 0.2em;
  font-weight: bold;
}

.global-padding {
  padding: $padding-page;
}

.page-padding-horizontal {
  padding-left: $padding-page-horizontal;
  padding-right: $padding-page-horizontal;
}

.page-padding-vertical {
  padding-left: $padding-page-vertical;
  padding-right: $padding-page-vertical;
}

.section-title {
  color: $theme-typo-headline-color;
  text-transform: uppercase;
  font-size: 1em;
  letter-spacing: 0.1em;
  font-weight: 700;
}

.full-size {
  position: fixed;
  height: 100vh;
  width: 100vw;
}

.centered {
  position: relative;
  margin: auto;
  min-width: 66vw;

  @include gt-sm() {
    min-width: 50vw;
  }

  @include gt-md() {
    min-width: 33vw;
  }
}

.monospace {
  font-family: $font-family-monospace;
}

.numeric-monospace {
  font-family: system-ui, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-variant-numeric: tabular-nums lining-nums;
}
