@import "ngx-toastr/toastr";

#toast-container {
  //prevent toast to be displayed under a notch
  @include if-ios-notch() {
    padding-top: var(--safe-area-inset-top);
  }
  @include if-ios-bottom-notch() {
    padding-bottom: var(--safe-area-inset-bottom);
  }

  .ngx-toastr {
    min-width: 300px;
    width: 70vw;

    .toast-message {
      white-space: pre-wrap;
    }
  }
}
