.loader-ellipsis {
  display: inline-block;
  position: absolute;
  // top: 50%;
  // left: 50%;
  // margin-left: -5%;
  // margin-top: -10%;
  top: calc(50% - (75px / 2));
  left: calc(50% - (75px / 2));
  height: 75px;
  width: 75px;
}

.loader-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #4c8230;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loader-ellipsis div:nth-child(1) {
  left: 8px;
  animation: loader-ellipsis1 0.6s infinite;
}

.loader-ellipsis div:nth-child(2) {
  left: 8px;
  animation: loader-ellipsis2 0.6s infinite;
}

.loader-ellipsis div:nth-child(3) {
  left: 32px;
  animation: loader-ellipsis2 0.6s infinite;
}

.loader-ellipsis div:nth-child(4) {
  left: 56px;
  animation: loader-ellipsis3 0.6s infinite;
}

@keyframes loader-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes loader-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes loader-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}
