@use 'node_modules/@ngneat/hot-toast/src/styles/styles.scss';
@import "shared";

body {
  hot-toast-container {
    > div {
      //prevent toast to be displayed under a notch
      @include if-ios-notch() {
        padding-top: var(--safe-area-inset-top);
      }
      @include if-ios-bottom-notch() {
        padding-bottom: var(--safe-area-inset-bottom);
      }
    }
  }
}
