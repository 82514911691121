@use "@angular/cdk" as cdk;
@import "~normalize.css/normalize.css";
@import "../shared";

/* You can add global styles to this file, and also import other style files */

html {
  width: 100%;
  height: 100vh;
  box-sizing: border-box; //apply border-box on every node
  font-size: 1em;
}

*,
*:before,
*:after {
  box-sizing: inherit; //apply border-box on every node
}

body {
  position: fixed;
  height: 100%;
  width: 100%;
  font-family: $font-family;
}

html,
body {
  margin: 0;
  padding: 0;
}

.hidden {
  display: none !important;
}

.visibility-hidden {
  visibility: hidden; //preserves element space
}

* {
  //évite le flash bleu sur le "tap" mobile. see: https://stackoverflow.com/questions/45049873/how-to-remove-the-blue-background-of-button-on-mobile
  -webkit-tap-highlight-color: transparent;
}

//évite le fond bleu sur l'auto-complete
input:-internal-autofill-selected {
  background-color: transparent !important;
  background-image: unset !important;
  color: inherit !important;
}

input {
  @include cdk.text-field-autofill-color(transparent);
}

//$input-background: rgb(249, 249, 249);

// Inputs

// .custom-input {
//   //Changement des styles des input uniquement sur ce composant
//   .mat-form-field-underline {
//     display: none;
//   }

//   .mat-form-field-flex {
//     background-color: $input-background;
//     padding: 1px 10px;
//     border-radius: 10px;
//   }
// }
/////////////////////////
///// CUSTOM MATERIAL ///
/////////////////////////

button {
  &:disabled {
    background-color: $background-color-lightgray-transparent;
  }
}

.mat-button {
  &.mat-accent {
    color: $theme-accent_light_color;
  }
}

// Changer la couleur du théme des inputs
.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: $font-color-darkgray;
}

.mat-form-field {
  .mat-form-field-label {
    color: $theme-primary_light_color;
    text-transform: uppercase;

    &.mat-form-field-empty {
      font-size: $font-placeholder-size;
      color: $font-color-darkgray;
    }
  }

  &.mat-focused {
    .mat-form-field-label {
      font-size: 1rem;
      color: $theme-primary_light_color;
    }
  }
}

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * Customize the background color to match your design.
 */

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

/*
 * A better looking default horizontal rule
 */

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

//décale bizarrement les élements vers le bas lorsque l'on utilise les flexbox => on désactive
// /*
//  * Remove the gap between audio, canvas, iframes,
//  * images, videos and the bottom of their containers:
//  * https://github.com/h5bp/html5-boilerplate/issues/440
//  */

// audio,
// canvas,
// iframe,
// img,
// svg,
// video {
//   vertical-align: middle;
// }

img {
  //https://ishadeed.com/article/defensive-css/#image-maximum-width
  max-width: 100%;
  object-fit: cover;
}

/*
 * Remove default fieldset styles.
 */

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */

textarea {
  resize: vertical;
}
