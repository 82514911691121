// Colors
$font-color-white: white;
$font-color-black: black;
$font-color-lightblack: rgb(51, 51, 51);
$font-color-gray: rgba(168, 168, 168);
$font-color-darkgray: rgba(82, 82, 87, 0.85);
$font-color-fulldarkgray: rgba(82, 82, 87, 1);
$font-color-lightgray: rgb(208, 208, 208);
// $font-color-mediumgray:rgba(82, 82, 87, 0.5);
// $font-color-red:red;
$font-color-error: red;

$font-size-dialog-title: 1.2rem;

// //background colors
$background-color-white: rgba(255, 255, 255, 1);
$background-color-verylightgray: rgba(249, 249, 249, 1);
$background-color-lightgray: rgba(240, 240, 240, 1);
$background-color-mediumgray: rgba(220, 220, 220, 1);
$background-color-gray: rgba(174, 174, 174, 1);
$background-color-lightgray-transparent: rgba(208, 208, 208, 0.3);
// $background-color-darkgray:rgba(82,82,87, 0.85);
$background-color-gray-transparent: rgba(82, 82, 82, 0.3);
$background-color-black-transparent: rgba(0, 0, 0, 0.07);
$background-color-black-half-transparent: rgba(0, 0, 0, 0.5);

//borders
$border-color-lightgray: #f2f2f2;

// // Background Images
// $background-image-landing: 'src/assets/images/backgrounds/landing.jpg';

// // Fonts
// $font-pacifico: Pacifico;
$font-montserrat: Montserrat;
$font-family: $font-montserrat, Roboto, "Helvetica Neue", sans-serif;

$font-family-monospace: monospace; //'B612 Mono', monospace;

// Font sizes
$font-placeholder-size: 0.8rem;

// // Margins & paddings
$padding-page-vertical: 10px;
$padding-page-horizontal: 30px;
$padding-page: $padding-page-vertical $padding-page-horizontal;
$padding-horizontal-desktop: max(30px, 15vh);
// $margin-top-page:10px;
// $margin-bottom-page:30px;
// $margin-left-page:20px;
// $margin-right-page:20px;
// $margin-page: $margin-top-page $margin-left-page $margin-bottom-page $margin-right-page;

// // Fixed elements
$fixed-bar-height: 56px;
$fixed-bar-height-desktop: 76px;
$fixed-header-height: 56px;

// // oters
$border-radius-tiny: 8px;
$border-radius-small: 10px;
$border-radius-medium: 20px;
$border-radius-big: 30px;
$tile-border-radius: 0.8rem;

$small-icon-size: 10px;
$medium-icon-size: 16px;
$normal-icon-size: 24px;

// //color inscriptions prestations
// $color-inscription-0:var(--mat-primary-500);
// $color-inscription-1:orange;
// $color-inscription-2:green;
// $color-inscription-3:brown;

// Theme vars
$theme-primary_base_color: var(--mat-primary-500); // mat-primary-base-color
$theme-primary_light_color: var(--mat-primary-200);

$theme-accent_base_color: var(--mat-accent-base-color);
$theme-accent_light_color: var(--mat-accent-200);

$theme-typo-headline-color: var(--typo-headline-color);
$theme-typo-title-color: var(--typo-title-color);
$theme-typo-subheading-color: var(--typo-subheading-color);

$gt-xs-height: 80vh;
$gt-xs-max-width: 45vw;
$gt-xs-width: 45vw;
$gt-xs-min-width: 400px;

$gt-xs-height-small: 60vh;
$gt-xs-max-width-small: 45vw;
$gt-xs-width-small: 45vw;
$gt-xs-min-width-small: 400px;
