:root {
    --secondary-color: gray;
    --accent-color:red;

    --primary-background: green;
    --secondary-background: darkgray;

    --mat-primary-base-color: #64e6e6;

    --success-back-color : #51A351;
    --success-font-color : #ffffff;
    --error-back-color: #BD362F;
    --error-font-color : #ffffff;
    --info-back-color: #2F96B4;
    --info-font-color : #ffffff;
    --warning-back-color: #F89406;
    --warning-font-color : #ffffff;

    // Mat Primary Theme
    // generated by http://mcg.mbitson.com/

    --mat-primary-50: #ecfcfc;
    --mat-primary-100: #d1f8f8;
    --mat-primary-200: #b2f3f3;
    --mat-primary-300: #93eeee;
    --mat-primary-400: #7beaea;
    --mat-primary-500: #64e6e6;
    --mat-primary-600: #5ce3e3;
    --mat-primary-700: #52dfdf;
    --mat-primary-800: #48dbdb;
    --mat-primary-900: #36d5d5;
    --mat-primary-A100: #ffffff;
    --mat-primary-A200: #ebffff;
    --mat-primary-A400: #b8ffff;
    --mat-primary-A700: #9effff;

    --mat-primary-contrast-50: #000000;
    --mat-primary-contrast-100: #000000;
    --mat-primary-contrast-200: #000000;
    --mat-primary-contrast-300: #ffffff;
    --mat-primary-contrast-400: #ffffff;
    --mat-primary-contrast-500: #ffffff;
    --mat-primary-contrast-600: #ffffff;
    --mat-primary-contrast-700: #ffffff;
    --mat-primary-contrast-800: #ffffff;
    --mat-primary-contrast-900: #ffffff;
    --mat-primary-contrast-A100: #000000;
    --mat-primary-contrast-A200: #000000;
    --mat-primary-contrast-A400: #000000;
    --mat-primary-contrast-A700: #000000;

    // Mat Accent Theme
    // generated by http://mcg.mbitson.com/

    --mat-accent-base-color: #525257;

    --mat-accent-50: #eaeaeb;
    --mat-accent-100: #cbcbcd;
    --mat-accent-200: #a9a9ab;
    --mat-accent-300: #868689;
    --mat-accent-400: #6c6c70;
    --mat-accent-500: #525257;
    --mat-accent-600: #4b4b4f;
    --mat-accent-700: #414146;
    --mat-accent-800: #38383c;
    --mat-accent-900: #28282c;
    --mat-accent-A100: #8585f5;
    --mat-accent-A200: #5656f2;
    --mat-accent-A400: #1515ff;
    --mat-accent-A700: #0000fb;

    --mat-accent-contrast-50: #000000;
    --mat-accent-contrast-100: #000000;
    --mat-accent-contrast-200: #000000;
    --mat-accent-contrast-300: #000000;
    --mat-accent-contrast-400: #ffffff;
    --mat-accent-contrast-500: #ffffff;
    --mat-accent-contrast-600: #ffffff;
    --mat-accent-contrast-700: #ffffff;
    --mat-accent-contrast-800: #ffffff;
    --mat-accent-contrast-900: #ffffff;
    --mat-accent-contrast-A100: #000000;
    --mat-accent-contrast-A200: #ffffff;
    --mat-accent-contrast-A400: #ffffff;
    --mat-accent-contrast-A700: #ffffff;
  }
