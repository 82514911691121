@mixin mat-icon-size($size: 24px) {
  font-size: $size;
  height: $size;
  width: $size;
  line-height: $size;

  box-sizing: content-box;
}

@mixin round-image($image-size: 20vw, $max-size: 100px) {
  $min-size: calc(min(#{$image-size}, #{$max-size}));

  width: $min-size;
  height: $min-size;
  min-width: $min-size;
  min-height: $min-size;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  border-radius: 50%;
}

// before adding new media mixins, see BreakpointObserver settings. example at http://localhost:4200/sandbox
@mixin gt-xs {
  @media screen and (min-width: 600px) {
    @content;
  }
}

@mixin gt-sm {
  @media screen and (min-width: 960px) {
    @content;
  }
}

@mixin gt-md {
  @media screen and (min-width: 1280px) {
    @content;
  }
}

@mixin bottom-sheet($size: normal) // '$size : normal | small'
 {
  // variables & small
  $width: $gt-xs-width;
  $height: $gt-xs-height;
  $max-width: $gt-xs-max-width;
  $min-width: $gt-xs-min-width;
  $margin: 10vh auto;

  @if $size == small {
    $width: $gt-xs-width-small;
    $height: $gt-xs-height-small;
    $max-width: $gt-xs-max-width-small;
    min-width: $gt-xs-min-width-small;
    $margin: 20vh auto;
  }

  // code
  // padding: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  font-family: $font-montserrat;

  position: fixed;
  top: 0;
  left: 0;

  @include gt-xs() {
    width: $width;
    height: $height;
    max-width: $max-width;
    min-width: $min-width;
    margin: $margin;

    position: initial;
    top: initial;
    left: initial;
  }
}

@mixin if-ios-notch() {
  // handle iOS notch : https://css-tricks.com/the-notch-and-css/
  // Browsers which fully support CSS Environment variables (iOS 11.2+).
  @supports (margin-top: env(safe-area-inset-top)) {
    --safe-area-inset-top: calc(env(safe-area-inset-top) - 5px);

    @content;
  }
}

@mixin ios-notch($set-background: true) {
  @include if-ios-notch() {
    padding-top: var(--safe-area-inset-top);

    @if $set-background {
      background: linear-gradient(
            transparent 0%,
            transparent var(--safe-area-inset-top),
            white var(--safe-area-inset-top),
            white 100%
          )
          no-repeat
          border-box,
        linear-gradient(
            90deg,
            var(--mat-primary-900) 0%,
            var(--mat-primary-500) 100%
          )
          no-repeat
          border-box;
    }

    @content;
  }
}

@mixin if-ios-bottom-notch() {
  @supports (margin-top: env(safe-area-inset-bottom)) {
    --safe-area-inset-bottom: env(safe-area-inset-bottom);

    @content;
  }
}

@mixin ios-bottom-notch() {
  @include if-ios-bottom-notch() {
    margin-bottom: var(--safe-area-inset-bottom);

    @content;
  }
}
