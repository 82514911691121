// Shake
@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}


// Slide Up & Fade  
@keyframes slideup-fade {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    height : 0;
    padding: 0;
  }

}

// Slide Down & Fade 
@keyframes slidedown-fade {
  0% {
    opacity: 0;
    height : 0;
  }

  100% {
    opacity: 1;
  }

}